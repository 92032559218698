html {
  height: 100%;
}

body {
  min-width: 1000px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Mui-selected {
  font-weight: 900 !important;
  background-color: #007d5b !important;
}

.MuiButton-textPrimary {
  color: #007d5b !important;
}

.Mui-focused {
  border-color: #007d5b !important;
}

.MuiInputBase-root:focus {
  outline: none !important;
}
.MuiPopper-root {
  z-index: 2147483647 !important;
}
.MuiFormLabel-root {
  border-color: #007d5b !important;
  outline: none !important;
}

.MuiPaper-root {
  font-family: 'Inter', sans-serif !important;
}

.MuiButtonBase-root,
.MuiPickersCalendarHeader-label,
.MuiTypography-root,
.MuiFormLabel-root {
  font-family: 'Inter', sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#myvideo {
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

button:focus {
  outline: 0;
}
input:focus {
  outline: 0;
}

body {
  font-family: 'Inter', sans-serif;
  color: rgba(0, 0, 0, 0.7);
  max-width: 100vw;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#root {
  font-family: 'Inter', sans-serif;
  color: rgba(0, 0, 0, 0.7);
  max-width: 100vw;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #f7fafc;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
a:link {
  color: inherit;
  text-decoration: none;
}
a:visited {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
a:active {
  color: inherit;
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

input[type='time']::-webkit-datetime-edit-hour-field:focus,
input[type='time']::-webkit-datetime-edit-minute-field:focus,
input[type='time']::-webkit-datetime-edit-second-field:focus,
input[type='time']::-webkit-datetime-edit-ampm-field:focus {
  background-color: #dae5f1;
  border-radius: 4px;
  color: rgba(66, 84, 102, 1);
}
