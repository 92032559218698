.wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  padding: 32px;
}
.output {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  max-width: 1000px;
}

.custom {
  width: 100%;
}

.uploaderCfg {
  --ctx-name: 'uploader';

  /* DO NOT FORGET TO USE YOUR OWN PUBLIC KEY */
  --cfg-pubkey: '022b237becbe5f170ab0';
  --cfg-multiple: 0;
  --cfg-confirm-upload: 1;
  --cfg-img-only: 1;
  --cfg-accept: '';
  --cfg-store: 1;
  --cfg-camera-mirror: 0;
  --cfg-source-list: 'local, url';
  --cfg-max-files: 1;
}
